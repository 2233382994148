.custom-card{
    background: #FFFFFF !important;
    box-shadow: 0px 7px 14px rgba(65, 69, 88, 0.1), 0px 3px 6px rgba(0, 0, 0, 0.07) !important;
    border-radius: 6px;
    padding: 0px;
    min-height: 100% !important;
}

.info-col{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 36px;
}

.analytics-col{
    display: flex;
    justify-content: center;
    align-items: center;
}



.LegendList {
  display: grid;
  grid-template-columns: 1fr 1fr;
  list-style: none; /* Remove default bullets */
}

.LegendList li {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.BulletLabel {
  display: flex;
  align-items: center;
}

.CirecleBullet {
  border-radius: 50%;
}

.BulletLabelText {
  margin-left: 10px;
}

.CenteredLabelValue {
  font-size: 36px;
  font-weight: 600;
  fill: #0088fe;
}


.table .thead-light2 th {
    color: #8898aa;
    background-color: #EDF2F8;
    border-color: #e9ecef; 
}

.period, .period:hover, .period:focus {
    color: rgb(238, 77, 77);
    margin-right: 10px;
    cursor: pointer;
    font-size: 0.8em;
}

.active-period, .active-period:hover, .active-period:focus {
    color: #EDF2F8;
    background-color: #2d7be5;
    padding: 5px 15px ;
    border-radius: 5px;
    margin-right: 10px;
    cursor: pointer;
    font-size: 0.8em;
}