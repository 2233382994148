.customInput{
    border: 0px;
    border-bottom: 1px solid gray;
    font-size: 15px;
    background-color: transparent;
    margin-top: 5px;
}

.custom-file-input::-webkit-file-upload-button {
    content: 'Select a tenants file';
    visibility: visible;
    display: inline-block;
    background: linear-gradient(top, #f9f9f9, #e3e3e3);
    border: 1px solid #999;
    border-radius: 3px;
    padding: 5px 8px;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    text-shadow: 1px 1px #fff;
    font-weight: 700;
    font-size: 10pt;
  }
  
  .custom-file-input:hover::before {
    border-color: black;
  }
  .custom-file-input:active::before {
    background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
  }