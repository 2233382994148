.custom-btn{
    color: white;
}

.success{
    background-color: #00D27B;
}

.warning{
    background-color: #F68F57;
}