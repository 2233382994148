
.custom-navbar{
    background-color: #222E3C;
    color: white !important;
}
.nav-item:focus{
    background-color: #4D5969 !important;
}

.child-menu-active, .child-menu-active:hover{
    background-color: rgba(13, 14, 17, 0.5);
    padding-left: 5%;
}

.child-menu-active:hover{
    color: #fff;
}

.child-menu-active > a{
    color: #525f7f;
}

.child-menu-active > a:hover, .child-menu-active > a:focus{
    color: #fff;
}

.child-menu {
    background-color: rgba(23, 27, 34, 0.5);
    padding-left: 5%;
}

.child-menu:hover, .child-menu:focus {
    background-color: rgba(23, 27, 34, 0.5);
    padding-left: 5%;
}

.parent-menu-active{
    background-color: #1F324A;
}


.active-child {
    top: 0.25rem;
    bottom: 0.25rem;
    left: 0;
    right: auto;
    border-left: 2px solid #aca20e;
    border-bottom: 0;
    color: #fff;
}