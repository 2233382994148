.header-body{
    margin-top: 20px;
}

.circle{
    border-radius: 50%;
    height: 10px;
    width: 10px;
    display: inline-block;
    margin-right: 10px;
}

.wrapper{
    display: flex;
    align-items: center;
    margin: 8px;
}

@media (min-width: 1500px){
    .custom-resize{
        flex: 0 0 25%;
        max-width: 25%;
    }
}